export class AppConfig {

    public static readonly AppVersion = '0.0.4';
    public static readonly API_URL: string = 'https://service.crowd9.co.za/MobileAppService/';
   // public static readonly API_URL: string = 'http://localhost:2000/MobileAppService/';
    public static readonly TOKEN_NAME = 'C9MembershipToken';
    public static readonly SIGN_IN_ENVIRONMENT = 'MOBILE';
    public static readonly APP_CONFIG = {};
    public static readonly STORAGE_CONFIG = {
        name: '__ppDb',
        driverOrder: ['indexeddb', 'sqlite', 'websql']
    };

}
