import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Device } from '@ionic-native/device/ngx';
import { AppConfig } from '../../app/app.config';
import { Observable } from 'rxjs';
import { Global } from '../global';

@Injectable()
export class APIService {
    constructor(
        private global: Global,
        private http: HttpClient,
        private storage: Storage,
        private device: Device) { }

    public async httpRequestWithAuthHeaders(location: string, requestData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let deviceUUID = this.device.uuid;
            if (deviceUUID == null) {
                deviceUUID = 'webapp';
            }

            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    DeviceUUID: deviceUUID,
                    AppEnvironment: AppConfig.SIGN_IN_ENVIRONMENT,
                    MobileAppVersion: AppConfig.AppVersion,
                    C9MembershipToken: this.global.GetUserToken(),
                    C9Account: this.global.GetSelectedOrganisationId().toString()
                })
            };

            return this.http.post(AppConfig.API_URL + location, requestData, httpOptions).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    public async httpRequestAnonymous(location: string, requestData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let deviceUUID = this.device.uuid;
            if (deviceUUID == null) {
                deviceUUID = 'webapp';
            }
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    DeviceUUID: deviceUUID,
                    SignInEnvironment: AppConfig.SIGN_IN_ENVIRONMENT,
                    MobileAppVersion: AppConfig.AppVersion
                })
            };
            return this.http.post(AppConfig.API_URL + location, requestData, httpOptions).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

}
