import { Injectable } from '@angular/core';
import { APIService } from '../services/api.service';

@Injectable()
export class DataListProvider {

    constructor(public service: APIService) {
    }

    GetMemberList(sendData: any) {
        return new Promise((result, exception) => {
            this.service.httpRequestWithAuthHeaders('GetMemberList', sendData).then(
                response => {
                    result(response);
                },
                error => { exception(error); });
        });
    }

    GetVisitorList(sendData: any) {
        return new Promise((result, exception) => {
            this.service.httpRequestWithAuthHeaders('GetVisitorList', sendData).then(
                response => {
                    result(response);
                },
                error => { exception(error); });
        });
    }

    GetImportantDates(sendData: any) {
        return new Promise((result, exception) => {
            this.service.httpRequestWithAuthHeaders('GetImportantDates', sendData).then(
                response => {
                    result(response);
                },
                error => { exception(error); });
        });
    }
    
}
