import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable()
export class Global {

    public readonly userTokenPosition: string = 'c9dxR';
    public readonly userOrganisationPosition: string = 'c9dxRO';
    private userToken = '';
    private userOrganisationList: any = [];
    private organisationId = 0;

    constructor(private storage: Storage) { }

    SetUserToken(tokenData: string): void {
        this.userToken = tokenData;
    }

    GetUserToken(): string {
        return this.userToken;
    }

    SetOrganisationList(organisationList: any): void {
        this.userOrganisationList = organisationList;
        if (this.organisationId === 0) {
            this.SetSelectedOrganisationId(this.userOrganisationList[0].Id);
        }
    }

    GetOrganisationList(): any {
        return this.userOrganisationList;
    }

    SetSelectedOrganisationId(newOrganisationId: number) {
        this.organisationId = +newOrganisationId;
        this.storage.set(this.userOrganisationPosition, +newOrganisationId);
    }

    GetSelectedOrganisationId(): number {
        return +this.organisationId;
    }

}
