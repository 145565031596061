import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';

import { Device } from '@ionic-native/device/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Global } from './global';

import { APIService } from '../app/services/api.service';

import { AuthProvider } from '../app/providers/auth.provider';
import { DataListProvider } from '../app/providers/data-list-provider';
import { DataDetailProvider } from '../app/providers/data-detail.provider';

import { MemberPreviewModule } from './pages/members-list/member-preview/member-preview.module';
import { VisitorPreviewModule } from './pages/visitors-list/visitor-preview/visitor-preview.module';

import { AppConfig } from './app.config';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(AppConfig.STORAGE_CONFIG),
    HttpClientModule,
    AppRoutingModule,
    MemberPreviewModule,
    VisitorPreviewModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Device,
    Global,
    APIService,
    AuthProvider,
    DataListProvider,
    DataDetailProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
