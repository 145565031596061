import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { DataDetailProvider } from '../../../providers/data-detail.provider';

@Component({
  selector: 'app-visitor-preview',
  templateUrl: 'visitor-preview.page.html',
  styleUrls: ['visitor-preview.page.scss']
})
export class VisitorPreviewPage implements OnInit {

  @Input() VisitorId: number;

  detailData: any = { FocusMemberDetails: {}, FamilyDetails: [] };
  showDetail = false;
  constructor(
    private nav: NavController,
    private modalCtrl: ModalController,
    private apiProvider: DataDetailProvider,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController
  ) { }

  ngOnInit() {
    this.loadPageData();
  }

  showAlert(alertContent: string) {
    this.alertCtrl.create({
      header: 'A problem occurred...',
      message: alertContent,
      buttons: ['Ok']
    }).then((alert) => {
      alert.present();
    });
  }

  loadPageData() {

    window.scroll(0, 0);

    this.detailData = { FocusMemberDetails: {}, FamilyDetails: [] };
    this.showDetail = false;
    const loader = this.loadingCtrl.create({ message: '' }).then((ldr) => {
      ldr.present();

      this.apiProvider.GetVisitorDetail({ RequestId: this.VisitorId }).then(
        (result: any) => {
          ldr.dismiss();
          if (result.HasError) {
            this.showAlert(result.Feedback);
          } else {
            this.showDetail = true;
            this.detailData = result;
          }
        }, (error) => {
          ldr.dismiss();
          this.showAlert(error.statusText);
        });
 
    });

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
 

}


