import { Injectable } from '@angular/core';
import { APIService } from '../services/api.service';

@Injectable()
export class AuthProvider {

    constructor(public service: APIService) {
    }

    SignUserIn(sendData: any) {
        return new Promise((result, exception) => {
            this.service.httpRequestAnonymous('SignUserIn', sendData).then(
                response => {
                    result(response);
                },
                error => { exception(error); });
        });
    }

}